<template>
    <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
            <strong><span>Logros</span></strong>
          </div>
          <div class="text item">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
                <el-row justify="space-around">
                  <el-col :sm="24" :md="24" :lg="24" align="middle">
                    <el-form-item label="Imagen de Logros">
                      <ImageUpload
                        :postImageTo="postImageTo"
                        :getImagesFrom="getGallery"
                        :setImage="form.image"
                        @getImage="getSelectedImage"
                      ></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row justify="space-around">
                  <el-col :sm="24" :md="24" :lg="24" align="left">
                    <el-form-item label="Título">
                      <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.title"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Descripción">
                      <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                        <el-input type="textarea" :rows="4" v-model="form.description"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-row justify="space-around">
                      <el-col :sm="24" :md="24" :lg="24" align="middle">
                        <el-form-item label="Imagen Sin Logros">
                          <ImageUpload
                            :postImageTo="postImageTo"
                            :getImagesFrom="getGallery"
                            :setImage="form.noAchievementsImage"
                            @getImage="getSelectedNoAchievementsImage"
                          ></ImageUpload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-form-item label="Titulo Sin Logros">
                      <ValidationProvider name="Titulo No Logros" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.noAchievementsTitle"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Agregar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              </el-form>
            </ValidationObserver>
          </div>
        </el-card>
      </el-col>
    </el-row>
    </div>
  </template>
<script>
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { createAchievements, getAchievements } from '@/api/app.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'createProject',
  components: {
    ImageUpload
  },
  data () {
    return {
      id: '65457e5b019b75831f02c2d2',
      host: urlServer,
      form: {
        title: '',
        description: '',
        image: null,
        url: '',
        noAchievementsImage: null,
        noAchievementsTitle: ''
      },
      loadingImage: false,
      imageSelected: false
    }
  },
  mounted () {
    this.handleFetch()
  },
  methods: {
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImage (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    getSelectedNoAchievementsImage (imageUploaded) {
      this.form.noAchievementsImage = imageUploaded
    },
    handlerCancel () {
      this.$router.push('/escrow-section')
    },
    async handleFetch () {
      try {
        const response = await getAchievements()
        if (response.success) {
          var data = await response.data
          if (data.length !== 0) {
            this.form = data
          }
        } else {
          console.log(response)
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    async handlerCreate (info) {
      console.log(this.form)
      if (this.form.image !== '' && this.form.image !== null) {
        try {
          const response = await createAchievements(this.form)
          console.log(this.form)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se cargó correctamente!',
              type: 'success'
            })
            // this.$router.push('/escrow-section')
          } else {
            console.log(response)
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.messages) {
            err.response.data.messages.forEach(message => {
              this.$message.error(`${message}`)
            })
          } else {
            console.log(err)
          }
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    }
  }
}
</script>
<style scoped>
.label-form-validate-wrong {
color: #F56C6C;
}
.size-form-item > .el-form-item {
margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
padding: 0;
margin-bottom: 0;
}
.my-autocomplete {
width: 500px;
}
</style>
